import Handlebars from "handlebars/dist/handlebars.min.js";

export function generateBibtex(publication) {
	const { type } = publication;
	const y = structuredClone(publication);
	delete y.type;
	y.type_bib = type.bib_type.bibtex_type;
	y.type = type.name;
	delete y.date;
	y.year = publication.date.substring(0, 4);
	y.month = publication.date.substring(5, 7);
	const authorsArr = publication.author.map(({ name, firstname }) => `${name}, ${firstname}`);
	const authorsnameArr = publication.author.map(({ name }) => name);
	y.authors = authorsArr.join(" and ");
	y.cite = `${authorsnameArr.join("-")}.${publication.date}`;
	const bibtexTemplate = `@{{type_bib}}{ {{cite}},
		{{#if title}}
		title={{{braced title }}},
		{{/if}}
		{{#if author}}
		author={{braced authors }},
		{{/if}}
		{{#if year}}
		year={{braced year }},
		{{/if}}		
		{{#if month}}
		month={{braced month }},
		{{/if}}
		{{#if journal}}
		journal={{{braced journal }}},
		{{/if}}
		{{#if url}}
		url={{{braced url }}},
		{{/if}}
		{{#if type}}
		type={{{braced type }}}
		{{/if}}
	}`;
	Handlebars.registerHelper("braced", function (aString) {
		return `{${aString}}`;
	});
	const template = Handlebars.compile(bibtexTemplate);
	return template(y);
}
export default generateBibtex;
