import React, { useEffect, useId, useState } from "react";
import { toast } from "react-toastify";
import CustomSelect from "../components/CustomSelect";
import "react-toastify/dist/ReactToastify.css";
import { fetchBackend } from "../logic/fetch-options";

function TypesForm({ type, setType, getTypes }) {
	const [bibtexType, setBibtexType] = useState(null);

	const updateType = async () => {
		fetchBackend(`/api/v1/type_post/${type.id}/`, "PUT", type).then(() => {
			getTypes();
			toast.success("The type has been updated!", { theme: "colored" });
			setType({ name: "", value: "", id: "" });
		});
	};

	const createType = async () => {
		fetchBackend(`/api/v1/type_post/`, "POST", type).then(() => {
			getTypes();
			toast.success("The type has been created!", { theme: "colored" });
			setType({ name: "", value: "" });
		});
	};

	const valInput = () => {
		if (type.name && type.bib_type) {
			if (type.id) {
				updateType();
			} else {
				createType();
			}
		} else {
			toast.error("Fill out all necessary Fields", { theme: "colored" });
		}
	};

	const getBibtexType = async () => {
		const response = await fetchBackend("/api/v1/bibtextype/");
		setBibtexType(response.data);
	};

	useEffect(() => {
		getBibtexType();
	}, []);
	const nameInput = useId();
	const bibtexTypeInput = useId();
	return (
		<div className="">
			<form className="flex flex-col flex-wrap gap-5 max-w-xs" onSubmit={(e) => e.preventDefault()}>
				<div>
					<label htmlFor={nameInput} className="text-sm mr-4 font-semibold block mb-2">
						Name*
					</label>
					<input
						type="text"
						id={nameInput}
						className="input input-bordered w-full max-w-xs"
						value={type?.name ?? ""}
						onChange={(e) => {
							setType({ ...type, name: e.target.value });
						}}
					/>
				</div>
				<div className="max-w-xs">
					<label htmlFor={bibtexTypeInput} className="text-sm mr-4 font-semibold block mb-2">
						Bibtex Type*
					</label>
					<CustomSelect
						options={bibtexType?.map((bibtype) => ({ label: bibtype.name, value: bibtype.id }))}
						value={bibtexType?.id}
						placeholder="Select Bibtex Type"
						bibtexTypeInput
						onChange={(value) => {
							setType({ ...type, bib_type: value.value });
						}}
					/>
				</div>
				<button onClick={valInput} type="submit" className="btn btn-sm btn-primary text-white">
					Submit
				</button>
			</form>
		</div>
	);
}

export default TypesForm;
