import React from "react";
import { Link } from "react-router-dom";
import Logout from "./Logout";

function Sidebar() {
	return (
		<div className="Sidebar">
			<ul className="navbar-nav">
				<Link className="nav-item nav-item-hover" to="/">
					<li>
						<i className="side-icon logo fi fi-paragraph" />
					</li>
				</Link>
				<Link className="nav-item side-icon nav-item-hover" to="/">
					<li className="tooltip tooltip-primary  tooltip-right" data-tip="Home">
						<ion-icon name="home" />
					</li>
				</Link>
				<Link className="nav-item side-icon nav-item-hover" to="/publication/add">
					<li className="tooltip tooltip-primary  tooltip-right" data-tip="Add Publication">
						<ion-icon name="document" />
					</li>
				</Link>
				<Link className="nav-item side-icon nav-item-hover" to="/authors/">
					<li className="tooltip tooltip-primary tooltip-right" data-tip="Authors">
						<ion-icon name="people" />
					</li>
				</Link>
				<Link className="side-icon nav-item nav-item-hover" to="/types/">
					<li className="tooltip tooltip-primary  tooltip-right" data-tip="Types">
						<ion-icon name="document-text" />
					</li>
				</Link>
				<Link className="side-icon nav-item nav-item-hover" to="/labels/">
					<li className="tooltip tooltip-primary  tooltip-right" data-tip="Labels">
						<ion-icon name="pricetag" />
					</li>
				</Link>
				<div className="side-icon nav-item">
					<Logout />
				</div>
			</ul>
		</div>
	);
}

export default Sidebar;
