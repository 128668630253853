import "./App.css";
import { Route, Navigate, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import Header from "./components/Header";
import Publications from "./components/Publications";
import Publication from "./pages/Publication";
import Sidebar from "./components/Sidebar";
import Authors from "./pages/Authors";
import Types from "./pages/Types";
import Labels from "./pages/Labels";
import PublicationFrom from "./pages/PublicationForm";
import Settings from "./pages/Settings";
import Login from "./components/Login";
import { AppContext, defaultAppContext } from "./logic/app-context";
import { checkIsLoggedIn } from "./logic/functions";

function App() {
	const [isLoading, setIsLoading] = useState(true);
	const [appContext, setAppContext] = useState({
		...defaultAppContext,
		setIsLoggedIn: (isLoggedIn) => {
			setAppContext((a) => ({ ...a, isLoggedIn }));
		}
	});

	useEffect(() => {
		const loadData = async () => {
			const isLoggedIn = await checkIsLoggedIn();
			setAppContext((a) => ({
				...a,
				isLoggedIn
			}));
			setIsLoading(false);
		};
		loadData();
	}, []);

	return (
		!isLoading && (
			<AppContext.Provider value={appContext}>
				{appContext.isLoggedIn ? (
					<>
						<Header />
						<Sidebar />
						<div className="App" data-theme="emerald">
							<div className="Publications">
								<Routes>
									<Route path="/settings/" element={<Settings />} />
									<Route path="/authors/" element={<Authors />} />
									<Route path="/types/" element={<Types />} />
									<Route path="/labels/" element={<Labels />} />
									<Route path="/publication/add/" element={<PublicationFrom />} />
									<Route path="/publication/:id/" element={<Publication />} />
									<Route path="/publication/:id/edit/" element={<PublicationFrom />} />
									<Route path="/" element={<Publications />} />
									<Route path="*" element={<Navigate to="/" />} />
								</Routes>
							</div>
						</div>
					</>
				) : (
					<Routes>
						<Route path="/" element={<Login />} />
						<Route path="*" element={<Navigate to="/" />} />
					</Routes>
				)}
				<ToastContainer />
			</AppContext.Provider>
		)
	);
}

export default App;
