import React, { useId, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomSelect from "../components/CustomSelect";
import { generateBibtex } from "../logic/bibtex-generator/bibtext-generator.js";
import { useGetMasterData } from "../logic/use-get-masterdata";
import { fetchBackend } from "../logic/fetch-options";

function Create() {
	const { id } = useParams();
	const [publication, setPublication] = useState({ date: new Date().toISOString().slice(0, 10) });
	const navigate = useNavigate();
	const { authors, labels, types } = useGetMasterData();
	const [headline, setHeadline] = useState("");

	const cleanPublication = (data) => {
		const x = data;
		if (!x.url) {
			delete x.url;
			setPublication(x);
		}
		setPublication(x);
	};

	const getPublication = async () => {
		const response = await fetchBackend(`/api/v1/publication/${id}/`);
		cleanPublication(response.data);
	};

	const prepPublication = () => {
		const pub = structuredClone(publication);
		delete pub.author;
		if (publication.author) {
			pub.author = publication.author.map(({ id }) => id);
		}
		delete pub.label;
		if (publication.label) {
			pub.label = publication.label.map(({ id }) => id);
		}
		delete pub.type;
		if (publication.type) {
			pub.type = publication.type.id;
		}
		return pub;
	};

	const createPublication = async () => {
		const pub = prepPublication();
		fetchBackend(`/api/v1/pub_post/`, "POST", pub).then(() => {
			toast.success("Publication created successfully", { theme: "colored" });
			navigate("/");
		});
	};
	const updatePublication = async () => {
		const pub = prepPublication();
		fetchBackend(`/api/v1/pub_post/${id}/`, "PUT", pub).then(() => {
			toast.success("Publication updated successfully", { theme: "colored" });
			navigate("/");
		});
	};

	const valPublication = () => {
		if (
			!publication.title ||
			publication.title === "" ||
			!publication.type ||
			!publication.author ||
			publication.author.length === 0
		) {
			toast.error("Fill out all necessary Fields", { theme: "colored" });
			return;
		}
		if (!publication.bibtex || publication.bibtex === "") {
			const bibtex = generateBibtex(publication);
			publication.bibtex = bibtex;
			publication.initBibtex = false;
		} else if (!id) publication.initBibtex = true;
		if (id) {
			if (publication.initBibtex) {
				updatePublication();
			} else {
				const bibtex = generateBibtex(publication);
				publication.bibtex = bibtex;
				updatePublication();
			}
		} else {
			createPublication();
		}
	};

	const checkId = () => {
		if (id) {
			setHeadline("Update");
			getPublication();
		} else {
			setHeadline("Add");
		}
	};

	const titleInput = useId();
	const dateInput = useId();
	const typeInput = useId();
	const urlInput = useId();
	const journalInput = useId();
	const authorsInput = useId();
	const labelsInput = useId();
	const bibtexInput = useId();
	const fileInput = useId();

	useEffect(() => {
		checkId();
	}, []);
	return (
		<div className="">
			<h1 className="font-bold leading-tight text-xl mb-8">{headline} Publication</h1>
			<form className="lg:grid flex flex-col grid-cols-2 gap-5  min-h-96 max-w-6xl">
				<div className="sm:grid flex flex-col grid-cols-2 gap-5">
					<div>
						<label htmlFor={titleInput} className="text-sm mr-4 font-semibold block mb-2">
							Title*
						</label>
						<input
							type="text"
							id={titleInput}
							className="input input-bordered w-full max-w-s"
							value={publication?.title ?? ""}
							onChange={(e) => {
								setPublication({ ...publication, title: e.target.value });
							}}
						/>
					</div>
					<div>
						<label htmlFor={typeInput} className="text-sm mr-4 font-semibold block mb-2">
							Type*
						</label>
						<CustomSelect
							options={types.map((type) => ({ label: type.name, value: type.id }))}
							placeholder="Select Type"
							onChange={(value) => {
								setPublication({ ...publication, type: types.find((x) => x.id === value.value) });
							}}
							value={publication?.type?.id}
						/>
					</div>
					<div>
						<label htmlFor={authorsInput} className="text-sm mr-4 font-semibold block mb-2 ">
							Authors*
						</label>
						<CustomSelect
							options={authors.map((author) => ({ label: author.name, value: author.id }))}
							placeholder="Select Author"
							className="min-w-max"
							isMulti
							id={authorsInput}
							onChange={(value) => {
								setPublication({
									...publication,
									author: value.map((author) => authors.find((x) => x.id === author.value))
								});
							}}
							value={publication?.author?.map((a) => a.id)}
						/>
					</div>
					<div>
						<label htmlFor={dateInput} className="text-sm mr-4 font-semibold block mb-2">
							Date*
						</label>
						<input
							type="Date"
							id={dateInput}
							value={publication?.date ?? ""}
							className="input input-bordered w-full max-w-s datepicker-input"
							onChange={(e) => {
								setPublication({ ...publication, date: e.target.value });
							}}
						/>
					</div>
					<div>
						<label htmlFor={urlInput} className="text-sm mr-4 font-semibold block mb-2">
							URL
						</label>
						<input
							type="url"
							id={urlInput}
							value={publication?.url ?? ""}
							className="input input-bordered w-full max-w-s "
							onChange={(e) => {
								setPublication({ ...publication, url: e.target.value });
							}}
						/>
					</div>
					<div>
						<label htmlFor={journalInput} className="text-sm mr-4 font-semibold block mb-2">
							Journal
						</label>
						<input
							type="text"
							id={journalInput}
							value={publication?.journal ?? ""}
							className="input input-bordered w-full max-w-s "
							onChange={(e) => {
								setPublication({ ...publication, journal: e.target.value });
							}}
						/>
					</div>
					<div>
						<label htmlFor={labelsInput} className="text-sm mr-4 font-semibold block mb-2">
							Labels
						</label>
						<CustomSelect
							options={labels.map((label) => ({ label: label.label, value: label.id }))}
							placeholder="Select Labels"
							isMulti
							id={labelsInput}
							onChange={(value) => {
								setPublication({
									...publication,
									label: value.map((label) => labels.find((x) => x.id === label.value))
								});
							}}
							value={publication?.label?.map((l) => l.id)}
						/>
					</div>
					<div>
						<label htmlFor={fileInput} className="text-sm mr-4 font-semibold block mb-2">
							File URL
						</label>
						<input
							type="url"
							id={fileInput}
							value={publication?.filepath ?? ""}
							className="input input-bordered w-full max-w-s "
							onChange={(e) => {
								setPublication({ ...publication, filepath: e.target.value });
							}}
						/>
					</div>
				</div>
				<div className="grid items-baseline w-full">
					<div className="min-w-full">
						<label htmlFor={bibtexInput} className="text-sm mr-4 font-semibold block mb-2">
							Bibtex
						</label>
						<textarea
							id={bibtexInput}
							value={publication?.bibtex ?? ""}
							rows={8}
							onChange={(e) => {
								setPublication({ ...publication, bibtex: e.target.value });
							}}
							style={{ resize: "none" }}
							className="code leading-4 textarea textarea-bordered min-w-full"
						/>
					</div>
				</div>
				<button
					onClick={valPublication}
					type="button"
					className="btn btn-sm btn-primary text-white grid-col"
					style={{ gridColumn: "2 span" }}>
					Submit
				</button>
			</form>
		</div>
	);
}

export default Create;
