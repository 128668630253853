import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import LabelsForm from "./LabelsForm";
import Modal from "../components/Modal";
import { fetchBackend } from "../logic/fetch-options";

function Labels() {
	const [labels, setLabels] = useState([]);
	const [selectedLabel, setSelectedLabel] = useState("");
	const [selectedId, setSelectedId] = useState();

	const getLabels = async () => {
		const response = await fetchBackend("/api/v1/label/");
		setLabels(response.data);
	};

	const deleteLabel = async () => {
		fetchBackend(`/api/v1/label/${selectedId}/`, "DELETE").then(() => {
			getLabels();
			toast.info("Label has been deleted", { theme: "colored" });
		});
	};

	useEffect(() => {
		getLabels();
	}, []);
	return (
		<div>
			<Modal isopen={selectedId !== undefined} title="WARNING">
				<p>Do you really want to delete the label?</p>
				<div className="card-actions justify-end">
					<button
						onClick={() => setSelectedId(undefined)}
						type="button"
						className="btn btn-outline text-gray-500 hover:text-white hover:bg-gray-500 hover:border-gray-500 border-gray-500 btn-sm">
						Cancel
					</button>
					<button
						onClick={() => {
							deleteLabel();
							setSelectedId(undefined);
						}}
						type="button"
						className="btn btn-sm btn-error hover:bg-red-500  text-white">
						Delete
					</button>
				</div>
			</Modal>
			<h1 className="font-bold leading-tight text-xl mb-5">Labels</h1>
			<div className="grid lg:grid-flow-col gap-10 grid-flow-row">
				<div className="max-w-auto max-h-80vh overflow-x-auto shadow-md sm:rounded-lg h-fit w-auto">
					<table className="w-full text-sm text-left text-gray-500 relative">
						<thead className="text-xs text-gray-700 uppercase bg-gray-100">
							<tr>
								<th scope="col" className="px-6 py-3 sticky">
									Label Name
								</th>
								<th scope="col" className="px-6 py-3">
									Actions
									<span className="sr-only">Edit</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{labels.map((label) => (
								<tr key={label.id} className="bg-white hover:bg-gray-50">
									<th
										scope="row"
										className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap max-w-xs truncate">
										{label.label}
									</th>
									<td className="px-6 py-4 grid gap-1 grid-flow-col justify-start">
										<button
											type="button"
											className="btn btn-sm max-w-fit btn-ghost bg-white p-1 text-gray-400 hover:text-white hover:bg-amber-400 shadow-lg"
											title="Edit"
											onClick={() => {
												setSelectedLabel({
													...selectedLabel,
													id: label.id,
													label: label.label
												});
											}}>
											<i className="action im im-pencil" />
										</button>
										<button
											type="button"
											className="btn btn-sm max-w-fit btn-ghost bg-white p-1 text-gray-400 hover:text-white hover:bg-error shadow-lg"
											title="Delete"
											onClick={() => setSelectedId(label.id)}>
											<i className="action fi fi-trash" />
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>

				<LabelsForm label={selectedLabel} setLabel={setSelectedLabel} getLabels={getLabels} />
			</div>
		</div>
	);
}

export default Labels;
