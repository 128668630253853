import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import AuthorsForm from "./AuthorsForm";
import Modal from "../components/Modal";
import { fetchBackend } from "../logic/fetch-options";

function Authors() {
	const [authors, setAuthors] = useState([]);
	const [selectedAuthor, setSelectedAuthor] = useState("");
	const [selectedId, setSelectedId] = useState();

	const getAuthors = async () => {
		const response = await fetchBackend("/api/v1/author/");
		setAuthors(response.data);
	};

	const deleteAuthor = async () => {
		fetchBackend(`/api/v1/author/${selectedId}/`, "DELETE").then(() => {
			getAuthors();
			toast.info("Author has been deleted", { theme: "colored" });
		});
	};

	useEffect(() => {
		getAuthors();
	}, []);
	return (
		<div>
			<Modal isopen={selectedId !== undefined} title="WARNING">
				<p>Do you really want to delete the author?</p>
				<div className="card-actions justify-end">
					<button
						onClick={() => setSelectedId(undefined)}
						type="button"
						className="btn btn-outline text-gray-500 hover:text-white hover:bg-gray-500 hover:border-gray-500 border-gray-500 btn-sm">
						Cancel
					</button>
					<button
						onClick={() => {
							deleteAuthor();
							setSelectedId(undefined);
						}}
						type="button"
						className="btn btn-sm btn-error hover:bg-red-500  text-white">
						Delete
					</button>
				</div>
			</Modal>
			<h1 className="font-bold leading-tight text-xl mb-5">Authors</h1>
			<div className="grid lg:grid-flow-col gap-10 grid-flow-row">
				<div className="max-w-auto overflow-x-auto max-h-80vh shadow-md sm:rounded-lg h-fit w-auto">
					<table className="w-full text-sm text-left text-gray-500 relative">
						<thead className="text-xs text-gray-700 uppercase bg-gray-100 ">
							<tr>
								<th scope="col" className="px-6 py-3 ">
									Name
								</th>
								<th scope="col" className="px-6 py-3 ">
									First Name
								</th>
								<th scope="col" className="px-6 py-3 ">
									Actions
									<span className="sr-only">Edit</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{authors.map((author) => (
								<tr key={author.id} className="bg-white hover:bg-gray-50">
									<th
										scope="row"
										className="px-6 py-4 font-medium text-gray-900 text-white whitespace-nowrap max-w-xs truncate">
										{author.name}
									</th>
									<td className="px-6 py-4">{author.firstname}</td>
									<td className="px-6 py-4 grid gap-1 grid-flow-col justify-start">
										<button
											type="button"
											className="btn btn-sm max-w-fit btn-ghost bg-white p-1 text-gray-400 hover:text-white hover:bg-amber-400 shadow-lg"
											title="Edit"
											onClick={() => {
												setSelectedAuthor({
													id: author.id,
													name: author.name,
													firstname: author.firstname
												});
											}}>
											<i className="action im im-pencil" />
										</button>
										<button
											type="button"
											className="btn btn-sm max-w-fit btn-ghost bg-white p-1 text-gray-400 hover:text-white hover:bg-error shadow-lg"
											title="Delete"
											onClick={() => {
												setSelectedId(author.id);
											}}>
											<i className="action fi fi-trash" />
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>

				<AuthorsForm author={selectedAuthor} setAuthor={setSelectedAuthor} getAuthors={getAuthors} />
			</div>
		</div>
	);
}

export default Authors;
