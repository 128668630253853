import React, { useState } from "react";
import { toast } from "react-toastify";
import Modal from "./Modal.jsx";
import { useAppContext } from "../logic/app-context.jsx";
import { fetchBackend } from "../logic/fetch-options.js";

function Logout() {
	const { setIsLoggedIn } = useAppContext();
	const [logout, setLogout] = useState(false);
	const logoutFunction = async () => {
		fetchBackend(`/logout/`).then((response) => {
			if (response.status === 200) {
				setIsLoggedIn(false);
				toast.info("Successfully logged out", { theme: "colored" });
			}
		});
	};
	return (
		<div>
			<Modal isopen={logout} title="WARNING">
				<p>Do you really want to log out?</p>
				<div className="card-actions justify-end">
					<form className="flex gap-2" onSubmit={(e) => e.preventDefault()}>
						<button
							onClick={() => setLogout(false)}
							type="button"
							className="btn btn-outline text-gray-500 hover:text-white hover:bg-gray-500 hover:border-gray-500 border-gray-500 btn-sm">
							Cancel
						</button>
						<button
							onClick={() => logoutFunction()}
							type="submit"
							className="btn btn-sm btn-error hover:bg-red-500  text-white">
							Logout
						</button>
					</form>
				</div>
			</Modal>
			<button
				type="button"
				className="nav-item-logout"
				onClick={() => {
					setLogout(true);
				}}>
				<li className="tooltip tooltip-error  tooltip-right" data-tip="Logout">
					<ion-icon name="log-out" />
				</li>
			</button>
		</div>
	);
}

export default Logout;
