import React, { useState, useEffect, useId, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomSelect from "./CustomSelect";
import Modal from "./Modal";
import { fetchBackend } from "../logic/fetch-options.js";

import { useGetMasterData } from "../logic/use-get-masterdata";

function Publications() {
	const [publications, setPublications] = useState([]);
	const [selectedId, setSelectedId] = useState();
	const { authors, labels, types, years, refetch } = useGetMasterData();
	const [count, setCount] = useState([]);
	const [limit, setLimit] = useState(1000);
	const [search, setSearch] = useState({ type: null, searchInput: null, year: null, label: null, author: null });
	const timer = useRef(null);
	const navigate = useNavigate();

	const searchItems = async () => {
		const response = await fetchBackend(
			`/api/v1/publication/?search=${search.searchInput}&type=${search.type}&date__year=${search.year}${search.label}${search.author}&limit=${limit}`
		);
		const data = response.data;
		setCount(data.count);
		setPublications(data.results);
	};

	const valSearch = () => {
		if (search.type === null) {
			setSearch({ ...search, type: "" });
			return;
		}
		if (search.searchInput === null) {
			setSearch({ ...search, searchInput: "" });
			return;
		}
		if (search.year === null) {
			setSearch({ ...search, year: "" });
			return;
		}
		if (search.label === null) {
			setSearch({ ...search, label: "" });
			return;
		}
		if (search.author === null) {
			setSearch({ ...search, author: "" });
			return;
		}
		searchItems();
	};

	const setSearchLabel = (value) => {
		if (value) {
			setSearch({ ...search, label: `&label=${value}` });
		} else {
			setSearch({ ...search, label: "" });
		}
	};

	const setSearchAuthor = (value) => {
		if (value) {
			setSearch({ ...search, author: `&author=${value}` });
		} else {
			setSearch({ ...search, author: "" });
		}
	};

	useEffect(() => {
		if (
			search?.searchInput !== null ||
			search?.type !== null ||
			search?.year !== null ||
			search?.label !== null ||
			search?.author !== null
		) {
			if (timer.current) {
				clearTimeout(timer.current);
			}
			timer.current = setTimeout(() => {
				valSearch();
			}, 500);
		}
	}, [search]);

	const getPublications = async () => {
		const response = await fetchBackend(`/api/v1/publication/?limit=${limit}`);
		const data = response.data;
		setCount(data.count);
		setPublications(data.results);
	};

	const deletePublication = async () => {
		fetchBackend(`/api/v1/publication/${selectedId}/`, "DELETE").then(() => {
			getPublications();
			refetch();
			const newcount = count - 1;
			setCount(newcount);
			toast.info("The publication has been deleted", { theme: "colored" });
		});
	};

	useEffect(() => {
		getPublications();
		setLimit(100);
	}, []);

	const searchID = useId();
	return (
		<>
			<Modal isopen={selectedId !== undefined} title="WARNING">
				<p>Do you really want to delete the publication?</p>
				<div className="card-actions justify-end">
					<form className="flex gap-2" onSubmit={(e) => e.preventDefault()}>
						<button
							onClick={() => setSelectedId(undefined)}
							type="button"
							className="btn btn-outline text-gray-500 hover:text-white hover:bg-gray-500 hover:border-gray-500 border-gray-500 btn-sm">
							Cancel
						</button>
						<button
							onClick={() => {
								deletePublication();
								setSelectedId(undefined);
							}}
							type="submit"
							className="btn btn-sm btn-error hover:bg-red-500  text-white">
							Delete
						</button>
					</form>
				</div>
			</Modal>
			<div className="grid grid-flow-col justify-between">
				<h1 className="font-bold leading-tight text-xl mb-5 ">Publications</h1>
				<a href="/publication/add">
					<button type="button" className="btn btn-primary btn-sm w-fit mb-5 text-white">
						Add Publication
					</button>
				</a>
			</div>
			<div className="publication_main overflow-x-auto pub-table w-auto ">
				<div>
					<div className="p-4 bg-gray-100 grid grid-flow-col gap-5 justify-between items-center min-w-fit rounded-t-lg shadow-md">
						<div className=" bg-gray-100 grid grid-flow-col gap-5 justify-between items-center ">
							<label htmlFor={searchID} className="sr-only">
								Search
							</label>
							<div className="relative">
								<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
									<i className="fi fi-search text-gray-500" />
								</div>
								<form onSubmit={(e) => e.preventDefault()}>
									<input
										type="text"
										id={searchID}
										className="input w-full max-w-xs border-gray-300 focus:border-primary block pl-10"
										placeholder="Search for items"
										onChange={(e) => setSearch({ ...search, searchInput: e.target.value })}
									/>
								</form>
							</div>
							<div className="Select max-w-xs">
								<CustomSelect
									options={types.map((type) => ({ label: type.name, value: type.id }))}
									value={types?.id}
									placeholder="Type"
									isClearable
									onChange={(value) => {
										setSearch({ ...search, type: value?.value ?? "" });
									}}
								/>
							</div>
							<div className="Select">
								<CustomSelect
									options={years.map((year) => ({ label: year.year, value: year.year }))}
									value={years?.year}
									placeholder="Year"
									isClearable
									onChange={(value) => {
										setSearch({ ...search, year: value?.value ?? "" });
									}}
								/>
							</div>
							<div className="Select ">
								<CustomSelect
									options={labels.map((label) => ({ label: label.label, value: label.id }))}
									value={labels?.id}
									isClearable
									placeholder="Labels"
									onChange={(value) => {
										setSearchLabel(value?.value);
									}}
								/>
							</div>
							<div className="Select">
								<CustomSelect
									options={authors.map((author) => ({ label: author.name, value: author.id }))}
									value={authors?.id}
									placeholder="Authors"
									isClearable
									onChange={(value) => {
										setSearchAuthor(value?.value);
									}}
								/>
							</div>
						</div>
						<div className="text-primary">
							{(count > 1 || count === 0) && <div> {count} Publications found</div>}
							{count === 1 && <div> {count} Publication found</div>}
						</div>
					</div>
					<table className="w-full text-sm text-left text-gray-500 relative rounded-b-lg overflow-hidden shadow-md">
						<thead className="text-xs text-gray-700 uppercase bg-gray-100">
							<tr>
								<th scope="col" className="px-6 py-3">
									Title
								</th>
								<th scope="col" className="px-6 py-3">
									Type
								</th>
								<th scope="col" className="px-6 py-3">
									Year
								</th>
								<th scope="col" className="px-6 py-3">
									Authors
								</th>
								<th scope="col" className="px-6 py-3">
									Labels
								</th>
								<th scope="col" className="px-6 py-3">
									Actions
									<span className="sr-only">Edit</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{publications.map((publication) => (
								<tr key={publication.id} className="bg-white hover:bg-gray-50 h-10">
									<th
										scope="row"
										className="px-6 py-4 font-medium whitespace-nowrap max-w-xs truncate">
										<Link
											className="hover:text-primary transition ease-in-out"
											to={`/publication/${publication.id}`}>
											{publication.title}
										</Link>
									</th>
									<td className="px-6 py-4">{publication.type.name}</td>
									<td className="px-6 py-4">{publication.date.substring(0, 4)}</td>
									<td className="px-6 py-4">
										{publication.author.map((author) => (
											<div key={author.id}>
												{author.name}, {author.firstname.charAt(0)}.
											</div>
										))}
									</td>
									<td>
										{publication.label.map((label) => (
											<div className="" key={label.id}>
												{label.label}
											</div>
										))}
									</td>
									<td>
										<div className="action px-6 py-4 grid grid-flow-col gap-1 justify-start">
											<button
												type="button"
												className="btn btn-sm max-w-fit btn-ghost bg-white p-1 text-gray-400 hover:text-white hover:bg-primary shadow-lg"
												title="Download PDF"
												disabled={!publication.filepath}
												onClick={() => {
													window.open(publication.filepath, "_blank").focus();
												}}>
												<i className="action fi fi-download" />
											</button>
											<button
												type="button"
												className="btn btn-sm max-w-fit btn-ghost bg-white p-1 text-gray-400 hover:text-white hover:bg-amber-400 shadow-lg"
												title="Edit"
												onClick={() => {
													navigate(`publication/${publication.id}/edit/`);
												}}>
												<i className="action im im-pencil" />
											</button>
											<button
												type="button"
												className="btn btn-sm max-w-fit btn-ghost bg-white p-1 text-gray-400 hover:text-white hover:bg-error shadow-lg"
												title="Delete"
												onClick={() => setSelectedId(publication.id)}>
												<i className="action fi fi-trash" />
											</button>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
}

export default Publications;
