import React, { useId } from "react";
import { toast } from "react-toastify";
import { fetchBackend } from "../logic/fetch-options";

function AuthorsForm({ author, setAuthor, getAuthors }) {
	const createAuthor = async () => {
		fetchBackend(`/api/v1/author/`, "POST", author).then(() => {
			getAuthors();
			toast.success("The author has been created!", { theme: "colored" });
			setAuthor({ name: "", firstname: "" });
		});
	};
	const updateAuthor = async () => {
		fetchBackend(`/api/v1/author/${author.id}/`, "PUT", author).then(() => {
			getAuthors();
			toast.success("The author has been updated!", { theme: "colored" });
			setAuthor({ name: "", firstname: "", id: "" });
		});
	};
	const nameInput = useId();
	const firstnameInput = useId();

	const valInput = () => {
		if (author.name && author.firstname) {
			if (author.id) {
				updateAuthor();
			} else {
				createAuthor();
			}
		} else {
			toast.error("Fill out all necessary Fields", { theme: "colored" });
		}
	};

	return (
		<div>
			<form className="flex flex-col flex-wrap gap-5 max-w-xs" onSubmit={(e) => e.preventDefault()}>
				<div>
					<label htmlFor={nameInput} className="text-sm mr-4 font-semibold">
						Name*
					</label>
					<input
						type="text"
						id={nameInput}
						className="input input-bordered w-full max-w-xs"
						value={author?.name ?? ""}
						onChange={(e) => {
							setAuthor({ ...author, name: e.target.value });
						}}
					/>
				</div>
				<div>
					<label htmlFor={firstnameInput} className="text-sm mr-4 font-semibold">
						First Name*
					</label>
					<input
						type="text"
						id={firstnameInput}
						value={author?.firstname ?? ""}
						className="input input-bordered w-full max-w-xs"
						onChange={(e) => {
							setAuthor({ ...author, firstname: e.target.value });
						}}
					/>
				</div>
				<button onClick={valInput} type="submit" className="btn btn-sm btn-primary text-white">
					Submit
				</button>
			</form>
		</div>
	);
}

export default AuthorsForm;
