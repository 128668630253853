import React from "react";
import { createPortal } from "react-dom";
import styles from "./Modal.module.css";

function Modal(props) {
	const { isopen, children, title } = props;

	const getModalClass = () => {
		let cn = `modal ${styles.modal}`;
		if (isopen === true) {
			cn += " modal-open";
		} else {
			cn += " hidden";
		}
		return cn;
	};

	return createPortal(
		<div className={getModalClass()}>
			<div className={`modal-box relative card w-96 bg-base-100 shadow-xl ${styles.modalBox}`}>
				<div className="card-body items-center text-center">
					<h2 className="card-title">{title}</h2>
					{children}
				</div>
			</div>
		</div>,
		document.body
	);
}

export default Modal;
