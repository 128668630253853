import { React } from "react";
import Logout from "../components/Logout.jsx";

function Settings() {
	return (
		<div>
			<h1 className="font-bold leading-tight text-xl mb-5 ">Settings</h1>
			<Logout />
		</div>
	);
}

export default Settings;
