let csrfToken = null;

// export const BACKEND_HOST = process.env.mode === "production" ? "" : "http://localhost:8000";
export const BACKEND_HOST = "https://pub.lmunity.work";
// export const BACKEND_HOST = "http://localhost:8000";
export const fetchBackend = async (url, method, body) => {
	if (!csrfToken) {
		// eslint-disable-next-line no-async-promise-executor
		csrfToken = new Promise(async (resolve) => {
			const res = await fetch(`${BACKEND_HOST}/csrf/`, {
				credentials: "include"
			});
			const data = await res.json();
			resolve(data.csrfToken);
		});
	}
	const getBody = () => {
		if (!body) {
			return body;
		}
		if (typeof body === "object") {
			return JSON.stringify(body);
		}
		return body;
	};

	const response = await fetch(BACKEND_HOST + url, {
		headers: {
			"X-CSRFToken": await csrfToken,
			"Content-Type": "application/json; charset=UTF-8"
		},
		credentials: "include",
		body: getBody(),
		method
	});
	if (response.status !== 500 && response.status !== 204 && response.status !== 403) {
		return response.json().then((data) => ({
			data,
			status: response.status
		}));
	}
	if (response.status === 403) {
		window.location.reload();
	}
	return response.status;
};
