import React, { useId } from "react";
import { toast } from "react-toastify";
import { fetchBackend } from "../logic/fetch-options";

function LabelsForm({ label, setLabel, getLabels }) {
	const createLabel = async () => {
		fetchBackend(`/api/v1/label/`, "POST", label).then(() => {
			getLabels();
			toast.success("The label has been created!", { theme: "colored" });
			setLabel({ label: "" });
		});
	};

	const updateLabel = async () => {
		fetchBackend(`/api/v1/label/${label.id}/`, "PUT", label).then(() => {
			getLabels();
			toast.success("The label has been updated!", { theme: "colored" });
			setLabel({ label: "", id: "" });
		});
	};
	const labelInput = useId();

	const valInput = () => {
		if (label.label) {
			if (label.id) {
				updateLabel();
			} else {
				createLabel();
			}
		} else {
			toast.error("Fill out all necessary Fields", { theme: "colored" });
		}
	};

	return (
		<div>
			<form className="flex flex-col flex-wrap gap-5 max-w-xs" onSubmit={(e) => e.preventDefault()}>
				<div>
					<label htmlFor={labelInput} className="text-sm mr-4 font-semibold">
						Label*
					</label>
					<input
						type="text"
						id={labelInput}
						className="input input-bordered w-full max-w-xs"
						value={label?.label ?? ""}
						onChange={(e) => {
							setLabel({ ...label, label: e.target.value });
						}}
					/>
				</div>
				<button onClick={valInput} type="submit" className="btn btn-sm btn-primary text-white">
					Submit
				</button>
			</form>
		</div>
	);
}

export default LabelsForm;
