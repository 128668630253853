import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "../components/Modal";
import { fetchBackend } from "../logic/fetch-options";

function Publication() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [publication, setPublication] = useState(null);
	const [disabledBTN, setDisabledBTN] = useState(null);
	const [selectedId, setSelectedId] = useState();

	const getPublication = async () => {
		const response = await fetchBackend(`/api/v1/publication/${id}/`);
		setPublication(response.data);
	};

	const preparePublication = () => {
		if (!publication?.filepath) {
			setDisabledBTN("disabled");
		} else {
			setDisabledBTN();
		}
	};
	const deletePublication = async () => {
		fetchBackend(`/api/v1/publication/${id}/`, "DELETE").then(() => {
			navigate("/");
		});
	};

	useEffect(() => {
		if (publication) {
			preparePublication();
		}
	}, [publication]);

	useEffect(() => {
		getPublication();
	}, [id]);

	return (
		<>
			<Modal isopen={selectedId !== undefined} title="WARNING">
				<p>Do you really want to delete the publication?</p>
				<div className="card-actions justify-end">
					<form className="flex gap-2" onSubmit={(e) => e.preventDefault()}>
						<button
							onClick={() => setSelectedId(undefined)}
							type="button"
							className="btn btn-outline text-gray-500 hover:text-white hover:bg-gray-500 hover:border-gray-500 border-gray-500 btn-sm">
							Cancel
						</button>
						<button
							onClick={() => {
								deletePublication();
								setSelectedId(undefined);
							}}
							type="submit"
							className="btn btn-sm btn-error hover:bg-red-500  text-white">
							Delete
						</button>
					</form>
				</div>
			</Modal>
			<div>
				<div className="leading-tight text-3xl mt-0 mb-2 font-bold">{publication?.title}</div>
			</div>
			<div className="lg:grid flex flex-col grid-cols-2 grid-flow-row gap-5 max-w-6xl">
				<div className="grid grid-flow-row gap-5 content-start">
					<div>
						<div className="font-bold">Release Date:</div>
						<div className="">{publication?.date}</div>
					</div>
					<div>
						<div className="font-bold">Publication Type:</div>
						<div className="">{publication?.type.name}</div>
					</div>
					<div>
						<div className="font-bold">Associated Authors:</div>

						<div className="flex gap-3 max-w-lg flex-wrap">
							{publication?.author.map((author) => (
								<div key={author.id} className="">
									{author.name}, {author.firstname.charAt(0)}.
								</div>
							))}
						</div>
					</div>
					<div>
						<div className="font-bold">Labels:</div>
						<div>
							{publication?.label.map((label) => (
								<div key={label.id}>{label.label}</div>
							))}
						</div>
					</div>
					<div>
						<div className="font-bold">URL:</div>
						<div className="">
							<a className="link text-primary" target="_blank" href={publication?.url} rel="noreferrer">
								{publication?.url}
							</a>
						</div>
					</div>
					<div>
						<div className="font-bold">File URL:</div>
						<div className="">
							<a
								className="link text-primary"
								target="_blank"
								href={publication?.filepath}
								rel="noreferrer">
								{publication?.filepath}
							</a>
						</div>
					</div>
					<div>
						<div className="font-bold">Journal:</div>
						<div className="">{publication?.journal}</div>
					</div>
					<div className="grid grid-flow-col gap-4 justify-start">
						<button
							type="button"
							className="btn btn-md btn-ghost bg-white text-gray-400 hover:text-white hover:bg-primary shadow-lg tooltip tooltip-primary tooltip-top"
							data-tip="Download"
							onClick={() => {
								window.open(publication.filepath, "_blank").focus();
							}}
							disabled={disabledBTN}>
							<i className="action-pub fi fi-download" />
						</button>
						<button
							type="button"
							className="btn btn-md btn-ghost bg-white text-gray-400 text-xl hover:text-white hover:bg-secondary shadow-lg tooltip tooltip-secondary tooltip-top"
							data-tip="Copy BibTex"
							onClick={() => {
								navigator.clipboard.writeText(publication?.bibtex);
								toast.success("Bibtex has been copied to clipboard", { theme: "colored" });
							}}>
							<ion-icon name="copy" />
						</button>
						<button
							type="button"
							className="btn btn-md max-w-fit btn-ghost bg-white text-gray-400 hover:text-white hover:bg-amber-400 shadow-lg tooltip tooltip-warning tooltip-top"
							data-tip="Edit"
							onClick={() => {
								navigate(`./edit/`);
							}}>
							<i className="action-pub im im-pencil" />
						</button>
						<button
							type="button"
							className="btn btn-md max-w-fit btn-ghost bg-white text-gray-400 hover:text-white hover:bg-error shadow-lg tooltip tooltip-error tooltip-top"
							data-tip="Delete"
							onClick={() => setSelectedId(publication.id)}>
							<i className="action-pub fi fi-trash" />
						</button>
					</div>
				</div>
				<div className="">
					<div className="font-bold">Bibtex:</div>
					<div className="bg-gray-200 rounded-lg p-2 text-xs max-w-xs overflow-x-auto">
						<div className="text-sm bibtex leading-4 whitespace-pre">{publication?.bibtex}</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Publication;
