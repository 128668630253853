import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import TypesForm from "./TypesForm";
import Modal from "../components/Modal";
import { fetchBackend } from "../logic/fetch-options";

function Types() {
	const [types, setTypes] = useState([]);
	const [selectedType, setSelectedType] = useState("");
	const [selectedId, setSelectedId] = useState();

	const getTypes = async () => {
		const response = await fetchBackend("/api/v1/type/");
		setTypes(response.data);
	};

	const valDelete = (response) => {
		if (response === 204) {
			getTypes();
			toast.info("Type has been deleted", { theme: "colored" });
		} else {
			toast.error("Type is still in use", { theme: "colored" });
		}
	};

	const deleteType = async () => {
		const response = await fetchBackend(`/api/v1/type/${selectedId}/`, "DELETE");
		valDelete(response);
	};

	useEffect(() => {
		getTypes();
	}, []);
	return (
		<div>
			<Modal isopen={selectedId !== undefined} title="WARNING">
				<p>Do you really want to delete the type?</p>
				<div className="card-actions justify-end">
					<button
						onClick={() => setSelectedId(undefined)}
						type="button"
						className="btn btn-outline text-gray-500 hover:text-white hover:bg-gray-500 hover:border-gray-500 border-gray-500 btn-sm">
						Cancel
					</button>
					<button
						onClick={() => {
							deleteType();
							setSelectedId(undefined);
						}}
						type="button"
						className="btn btn-sm btn-error hover:bg-red-500  text-white">
						Delete
					</button>
				</div>
			</Modal>
			<h1 className="font-bold leading-tight text-xl mb-5">Types</h1>
			<div className="grid lg:grid-flow-col gap-10 grid-flow-row">
				<div className="max-w-auto max-h-80vh overflow-x-auto shadow-md sm:rounded-lg h-fit w-auto">
					<table className="w-full text-sm text-left text-gray-500  relative">
						<thead className="text-xs text-gray-700 uppercase bg-gray-100">
							<tr>
								<th scope="col" className="px-6 py-3 sticky">
									Type Name
								</th>
								<th scope="col" className="px-6 py-3">
									Bibtex Type
								</th>
								<th scope="col" className="px-6 py-3">
									Actions
									<span className="sr-only">Edit</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{types.map((type) => (
								<tr key={type.id} className="bg-white hover:bg-gray-50">
									<th
										scope="row"
										className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap max-w-xs truncate">
										{type.name}
									</th>
									<td className="px-6 py-4">{type.bib_type.name}</td>
									<td className="px-6 py-4 grid gap-1 grid-flow-col justify-start">
										<button
											type="button"
											className="btn btn-sm max-w-fit btn-ghost bg-white p-1 text-gray-400 hover:text-white hover:bg-amber-400 shadow-lg"
											title="Edit"
											onClick={() => {
												setSelectedType({
													...selectedType,
													id: type.id,
													name: type.name,
													bibtextype: { name: type.bib_type.name, id: type.bib_type.id }
												});
											}}>
											<i className="action im im-pencil" />
										</button>
										<button
											type="button"
											className="btn btn-sm max-w-fit btn-ghost bg-white p-1 text-gray-400 hover:text-white hover:bg-error shadow-lg"
											title="Delete"
											onClick={() => setSelectedId(type.id)}>
											<i className="action fi fi-trash" />
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>

				<TypesForm type={selectedType} setType={setSelectedType} getTypes={getTypes} />
			</div>
		</div>
	);
}

export default Types;
