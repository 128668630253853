import React from "react";
import Select, { components } from "react-select";

function ValueContainer({ children, getValue, ...props }) {
	const values = getValue();
	const renderValues = [];

	if (values.length > 0) {
		renderValues.push(
			<span
				key={renderValues.length}
				style={{
					textOverflow: "ellipsis",
					maxWidth: "calc(100% - 70px)",
					marginRight: "0.15rem",
					overflow: "hidden",
					whiteSpace: "nowrap"
				}}>
				{props.selectProps.getOptionLabel(values[0])}
			</span>
		);
	}
	if (values.length > 1) {
		renderValues.push(` & ${values.length - 1} more`);
	}

	// Keep standard placeholder and input from react-select
	const childsToRender = React.Children.toArray(children).filter(
		(child) => ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
	);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<components.ValueContainer {...props}>
			{!props.selectProps.inputValue && renderValues}
			{childsToRender}
		</components.ValueContainer>
	);
}

function CustomSelect({ options, placeholder, isMulti, isClearable, id, onChange, value }) {
	const colourStyles = {
		control: (style) => ({
			...style,
			fontSize: ".85rem",
			color: "black",
			borderRadius: ".5rem",
			minHeight: "2.8rem",
			maxWidth: "20rem",
			border: "none",
			boxShadow: "",
			padding: "0px",
			margin: "0px"
		}),
		placeholder: (styles) => ({ ...styles, color: "#a3a3a3" }),
		valueContainer: (style) => ({
			...style,
			padding: "0px"
		}),
		menu: (style) => ({
			...style,
			width: "calc(100% + 1.5rem)",
			marginLeft: "-1rem",
			padding: "0px",
			borderRadius: "10px",
			overflow: "hidden"
		})
	};

	const getValue = () => {
		if (!value) {
			return undefined;
		}
		if (Array.isArray(value)) {
			return options.filter((o) => value.includes(o.value));
		}
		return options.find((o) => o.value === value);
	};

	return (
		<div className="customselect input input-bordered  max-w-s">
			<Select
				className=""
				isMulti={isMulti}
				isClearable={isClearable}
				styles={colourStyles}
				placeholder={placeholder}
				options={options}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					colors: {
						...theme.colors,
						primary25: "#f3f3f3",
						primary: "#66cc8a",
						primary50: "#f3f3f3"
					}
				})}
				components={isMulti ? { ValueContainer } : undefined}
				hideSelectedOptions={false}
				closeMenuOnSelect={!isMulti}
				id={id}
				onChange={onChange}
				value={getValue()}
			/>
		</div>
	);
}

export default CustomSelect;
