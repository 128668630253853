import { useEffect, useState } from "react";
import { fetchBackend } from "./fetch-options";

export const useGetMasterData = () => {
	const [authors, setAuthors] = useState([]);
	const [labels, setLabels] = useState([]);
	const [types, setTypes] = useState([]);
	const [years, setYears] = useState([]);

	const getYears = async () => {
		const response = await fetchBackend("/api/v1/years/");
		const data = response.data;
		const dataClean = data.filter((thing, index, self) => index === self.findIndex((t) => t.year === thing.year));
		setYears(dataClean);
	};

	const getTypes = async () => {
		const response = await fetchBackend("/api/v1/type/");
		const data = response.data;
		setTypes(data);
	};

	const getLabels = async () => {
		const response = await fetchBackend("/api/v1/label/");
		const data = response.data;
		setLabels(data);
	};

	const getAuthors = async () => {
		const response = await fetchBackend("/api/v1/author/");
		const data = response.data;
		setAuthors(data);
	};

	const loadData = () => {
		getYears();
		getTypes();
		getLabels();
		getAuthors();
	};

	useEffect(() => {
		loadData();
	}, []);

	return { authors, labels, types, years, refetch: loadData };
};
