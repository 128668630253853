import React, { useId, useState } from "react";
import { toast } from "react-toastify";
import { useAppContext } from "../logic/app-context.jsx";
import { fetchBackend } from "../logic/fetch-options.js";
import styles from "./Login.module.css";

function Login() {
	const [loginData, setLoginData] = useState();
	const { setIsLoggedIn } = useAppContext();
	const loginFunction = async () => {
		fetchBackend(`/login/`, "POST", loginData).then((data) => {
			if (data.status === 200) {
				setIsLoggedIn(true);
				window.location.reload();
				return toast.success("Login successfull", { theme: "colored" });
			}

			return toast.error("Invalid credentials", { theme: "colored" });
		});
	};
	const valInput = () => {
		if (loginData?.username && loginData?.password) {
			loginFunction();
		} else {
			toast.error("Please fill out all necessary fields", { theme: "colored" });
		}
	};
	const usernameInput = useId();
	const passwordInput = useId();
	return (
		<div className="login ">
			<div className="blurwrapper flex flex-col items-center justify-center">
				<div className={`logo ${styles.logo}`}>
					<i className="side-icon logo fi fi-paragraph mb-10 -ml-5" />
				</div>
				<form
					className={`flex flex-col flex-wrap gap-5 max-w-xs ${styles.loginbox}`}
					onSubmit={(e) => e.preventDefault()}>
					<div>
						<label htmlFor={usernameInput} className="text-sm mr-4 text-white drop-shadow-md">
							Username
						</label>
						<input
							type="text"
							id={usernameInput}
							onChange={(e) => {
								setLoginData({ ...loginData, username: e.target.value });
							}}
							className="input w-full max-w-xs"
						/>
					</div>
					<div>
						<label htmlFor={passwordInput} className="text-sm mr-4 text-white drop-shadow-md">
							Password
						</label>
						<input
							type="password"
							onChange={(e) => {
								setLoginData({ ...loginData, password: e.target.value });
							}}
							id={passwordInput}
							className="input w-full max-w-xs"
							data-tip="accent"
						/>
					</div>
					<button
						type="submit"
						onClick={() => {
							valInput();
						}}
						className="btn btn-sm btn-primary text-white">
						Submit
					</button>
				</form>
			</div>
		</div>
	);
}

export default Login;
